<template>
  <div class="co-pilot__input">
    <div class="co-pilot__form-group">
      <div class="mb-2 d-flex">
        <label
          :for="`${label}-202419031721`"
        >
          {{ label }}
          <span v-if="isOptional"> (optional) </span>
        </label>
        <span
          :id="`co-pilot-info-icon-${label}`"
          role="button"
          class="ml-2 info-icon"
        >
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 9c0-4.963 4.037-9 9-9s9 4.037 9 9-4.037 9-9 9-9-4.037-9-9zm8.167-3.674l-.01-.01a.543.543 0 01-.129-.362v-.778c0-.135.042-.26.13-.362l.004-.005.005-.005a.513.513 0 01.372-.154h.907c.144 0 .271.053.372.154a.513.513 0 01.154.372v.778c0 .143-.052.27-.154.372l-.004.005-.006.004a.543.543 0 01-.362.13H8.54a.543.543 0 01-.362-.13l-.01-.009zm.115 8.885l-.01-.01a.543.543 0 01-.129-.363V7.013a.49.49 0 01.14-.358.513.513 0 01.371-.153h.692c.139 0 .264.05.358.153a.468.468 0 01.153.358v6.825c0 .144-.052.271-.154.373a.49.49 0 01-.357.139h-.692a.543.543 0 01-.362-.13l-.01-.01z" fill="#35375B" opacity=".6"/></svg>
        </span>
        <b-tooltip
          :target="`co-pilot-info-icon-${label}`"
          triggers="hover"
          custom-class="tooltip-html-202419032140"
        >
          <div
            v-html="tooltipText"
          ></div>
        </b-tooltip>
      </div>
      <input
        v-if="isTextInputField"
        :id="`${label}-202419031721`"
        :placeholder="placeholder"
        type="text"
        class="form-control"
        :value="value"
        @input="onInputChange"
      >
      <textarea
        v-else
        :id="`${label}-202419031721`"
        :placeholder="placeholder"
        class="form-control"
        rows="2"
        :value="value"
        @input="onInputChange"
      >
      </textarea>
      <p
        v-if="!!helperText"
        v-html="helperText"
        class="helper-text"
      ></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CoPilotInput",
  props: {
    value: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false
    },
    isOptional: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String,
      default: ""
    },
    isTextInputField: {
      type: Boolean,
      default: true
    },
    helperText: {
      type: String,
      default: ""
    }
  },
  methods: {
    onInputChange (event) {
      this.$emit("input", event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.co-pilot {
  &__input {
    margin-bottom: 24px;
  }
  &__form-group {
    label {
      margin-bottom: 0;
      span {
        color: $slate40;
      }
    }
    .form-control {
      background-color: $white80;
      padding-left: 24px;
      color: $slate;
      &::placeholder {
        color: $slate60 !important;
        font-size: 16px;
      }
      &::-ms-input-placeholder { /* Edge 12 -18 */
        color: $slate60 !important;
      }
    }
    textarea {
      resize: none;
      border-radius: 14px !important;
      border: 1px solid transparent !important;
      &:focus {
        border: 1px solid rgba(42, 45, 82, 0.2) !important;
      }
    }
  }

  .info-icon {
    height: fit-content;
    svg {
      height: 16px;
      width: 16px;
      path {
        fill: $slate40;
        opacity: 1;
      }
    }
  }

  .helper-text {
    display: flex;
    margin: 0;
    color: $slate60;
    font-size: 16px;
    :deep(span) {
      margin-left: 8px;
    }
  }
}
</style>

<style lang="scss">

.tooltip-html-202419032140 {
  .tooltip-inner {
    color: $slate80;
    padding: 12px !important;
    max-width: 280px;
    text-align: left;
    p {
      margin: 0;
    }
    ul {
      margin: 0;
      padding-left: 24px;
      li {
        margin: 0;
      }
    }
  }
}

</style>
