<template>
  <div class="h-100 w-100 d-flex align-items-center justify-content-center">
    <div class="d-flex flex-column align-items-center">
      <co-pilot-loader
        class="m-b-50"
      />
      <p
        class="mb-3"
        v-html="evalString(currentLoadingText)"
      ></p>
      <div
        id="progress-bar"
        class="co-pilot__progress-bar"
      >
        <div class="progress"></div>
      </div>
    </div>
  </div>
</template>

<script>
import CoPilotLoader from "./CoPilotLoader.vue"

export default {
  name: "CoPilotLoadingScreen",
  components: {
    CoPilotLoader
  },
  data () {
    return {
      staticTextDefault: {
        loadingText1: "<i class='fas fa-solid fa-file'></i> Set up a structure for a cold call playbooks",
        loadingText2: "<i class='fas fa-solid fa-window-maximize'></i> Search website <u>{{getUrl}}</u> and extract relevant information",
        loadingText3: "🧚‍♀️ This is how you get past the secretary",
        loadingText4: "👩🏻‍💼 This is your value pitch for the decision-maker",
        loadingText5: "🙅🏻‍♀️ I anticipate the objections and create the appropriate objection handling for you",
        loadingText6: "<i class='fas fa-solid fa-check'></i> Now I coordinate all the items with each other"
      },
      currentLoadingText: "",
      loadingInterval: null
    }
  },
  props: {
    url: {
      required: true,
      type: String
    }
  },
  async mounted () {
    let currentTime = 0
    const interval = 5000
    const totalTime = 30000
    const textArray = [
      this.staticText.loadingText1,
      this.staticText.loadingText2,
      this.staticText.loadingText3,
      this.staticText.loadingText4,
      this.staticText.loadingText5,
      this.staticText.loadingText6
    ]
    let index = 0

    while (currentTime < totalTime) {
      this.currentLoadingText = textArray[index]
      await this.sleep(interval)
      index++
      currentTime += interval
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    getUrl () {
      return this.url
    }
  }
}
</script>

<style lang="scss" scoped>

.co-pilot {

  &__progress-bar {
    width: 316px;
    height: 10px;
    border-radius: 10px;
    background: #c8c4c1;
    overflow: hidden;

    .progress {
      height: 100%;
      width: 100%;
      background-color: $orange;
      animation: progressBar 30s linear;
    }
  }
  @keyframes progressBar {
    0% {
      width: 0%;
    }

    100% {
      width: 100%;
    }
  }
}
.m-b-50 {
  margin-bottom: 70px;
}
</style>
