<template>
  <div class="w-100 h-100">
    <div
      v-if="!creatingMode"
      class="co-pilot__create"
    >
      <div class="top-section">
        <div class="co-pilot__form-container">
          <co-pilot-progress
            class="mb-4"
          />
          <h1>{{ staticText.createPlaybookLabel }}</h1>
          <p class="sub-heading">{{ staticText.moreInformationLabel }}</p>
          <div class="pb-3">

            <co-pilot-dropdown
              v-model="conversationType"
              :label="staticText.conversationTypeLabel"
              :dropdownItems="conversationTypes"
            />

            <div>
              <co-pilot-input
                v-model="url"
                placeholder="https://example.com"
                :label="staticText.websiteToScanLabel"
                :tooltipText="staticText.websiteTooltipInfo"
                :helperText="staticText.websiteToScanHelper"
              />
            </div>

            <div>
              <co-pilot-input
                v-model="testimonial"
                :placeholder="staticText.typeHerePlaceholder"
                :label="staticText.whatCustomersSayLabel"
                :isOptional="true"
                :tooltipText="staticText.customersSayInfo"
                :isTextInputField="false"
              />
            </div>

            <div>
              <co-pilot-input
                v-model="rules"
                :placeholder="staticText.typeHerePlaceholder"
                :label="staticText.whatElseToKnow"
                :isOptional="true"
                :tooltipText="staticText.whatElseTooltip"
                :isTextInputField="false"
              />
            </div>

          <!-- <div class="marketing-material">
              <p>{{ staticText.marketingLabel }}</p>
              <p class="helper-text">{{ staticText.marketingHelperText }}</p>
              <button>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.354 2.146a.5.5 0 00-.708 0l-2.438 2.439 3.207 3.207 2.439-2.438a.5.5 0 000-.708l-2.5-2.5zM14.707 8.5L11.5 5.293l-9.354 9.353a.5.5 0 000 .708l2.5 2.5a.5.5 0 00.708 0L14.707 8.5z" fill="#7F8197"/><path class="wand" d="M7 2.5a2 2 0 002 2 2 2 0 00-2 2 2 2 0 00-2-2 2 2 0 002-2zM15.5 11a2 2 0 002 2 2 2 0 00-2 2 2 2 0 00-2-2 2 2 0 002-2z" fill="#7F8197" stroke="#7F8197" stroke-linecap="round" stroke-linejoin="round"/></svg>
                Upload
              </button>
            </div> -->
          </div>
        </div>
      </div>
      <footer>
        <router-link to="/co-pilot">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15 18l-6-6 6-6" stroke="#555775" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
          {{ staticText.backLabel }}
        </router-link>
        <button
          @click="createPlaybook"
          :disabled="!isRequiredInfoFilled"
        >
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.354 2.146a.5.5 0 00-.708 0l-2.438 2.439 3.207 3.207 2.439-2.438a.5.5 0 000-.708l-2.5-2.5zM14.707 8.5L11.5 5.293l-9.354 9.353a.5.5 0 000 .708l2.5 2.5a.5.5 0 00.708 0L14.707 8.5z" fill="#7F8197"/><path class="wand" d="M7 2.5a2 2 0 002 2 2 2 0 00-2 2 2 2 0 00-2-2 2 2 0 002-2zM15.5 11a2 2 0 002 2 2 2 0 00-2 2 2 2 0 00-2-2 2 2 0 002-2z" fill="#7F8197" stroke="#7F8197" stroke-linecap="round" stroke-linejoin="round"/></svg>
          {{ staticText.aiMagicLabel }}
        </button>
      </footer>
    </div>
    <co-pilot-loading-screen
      v-else
      :url="url"
    />
  </div>
</template>

<script>
import axios from "axios"
import CoPilotProgress from "./components/CoPilotProgress.vue"
import CoPilotInput from "./components/CoPilotInput.vue"
import CoPilotDropdown from "./components/CoPilotDropdown.vue"
import CoPilotLoadingScreen from "./components/CoPilotLoadingScreen.vue"

export default {
  name: "CoPilotCreate",
  components: {
    CoPilotProgress,
    CoPilotInput,
    CoPilotDropdown,
    CoPilotLoadingScreen
  },
  data () {
    return {
      url: "",
      targetCustomer: "",
      testimonial: "",
      rules: "",
      targetCustomers: [],
      conversationType: null,
      createPlaybookUrl: "/api/talkscripts/auto_create",
      creatingMode: false,
      showAdvancedOptions: false,
      staticTextDefault: {
        createPlaybookLabel: "Your perfect approach to new customers",
        moreInformationLabel: "Give me some information so that I can create a new playbook for you.",
        conversationTypeLabel: "For what kind of conversation do you need a playbook?",
        approachNewCustomers: "Approaching new customers",
        websiteToScanLabel: "Which website should I scan for relevant product information?",
        websiteTooltipInfo: "<ul><li>Must be freely accessible (no intranet pages)</li><li>Website must not contain any blockers</li><li>Relevant information must be available in text format (videos, for example, are not readable)</li></ul>",
        websiteToScanHelper: "Important: <span>At the moment I can only process German websites. We are already working on improving the system!</span>",
        whatCustomersSayLabel: "What do your customers say about your product?",
        customersSayInfo: "For example, insert quotes here",
        typeHerePlaceholder: "Type here",
        whatElseToKnow: "What else should I consider or know?",
        whatElseTooltip: "<p>Here you can enter additional information, such as</p><ul><li>Rules you want me to follow</li><li>Your target customers</li><li>Words I should (not) use</li></ul>",
        marketingLabel: "Further Documents",
        marketingHelperText: "If you like, I'll check your documents and see if I can find something that's suitable for pitching.",
        backLabel: "Back",
        aiMagicLabel: "Start AI Magic",
        errorLabel: "Error"
      },
      genaiToastId: "genai-service-error-toast",
      genaiErrorMessage: null
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    isRequiredInfoFilled () {
      return !!this.url && !!this.conversationType
    },
    conversationTypes () {
      return [
        this.staticText.approachNewCustomers
        // "Inbound call",
        // "Problem solution fit video call",
        // "Demo video call",
        // "Price negotiation"
      ]
    }
  },
  methods: {
    async createPlaybook () {
      this.hideGlobalToast()
      this.creatingMode = true
      const playbookData = {
        url: this.url,
        additional_data: {
          "Conversation-Type": this.conversationType,
          Rules: this.rules,
          Testimonial: this.testimonial
          // "Target Customer": this.targetCustomer
        }
      }
      await axios.post(
          `${this.createPlaybookUrl}`, // API url for playbook creation
          playbookData // POST data forwarded to bao genai service
      ).then(response => {
        this.$router.push(`/templates/edit/${response.data.id}?bao-copilot=true`)
      }).catch(err => {
        this.creatingMode = false
        this.genaiErrorMessage = this.generateErrorMsg(err)

        this.showErrorToast(this.genaiErrorMessage)
      })
    },
    generateErrorMsg (err) {
      const errorData = err.response.data
      if (!errorData.error) return errorData
      else return errorData.error.error || errorData.error
    },
    showErrorToast (description) {
      const errorMessage = {
        title: this.staticText.errorLabel,
        description
      }
      this.showGlobalToast({
        status: "error",
        message: errorMessage
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.co-pilot {
  &__create {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    h1 {
      line-height: 38.4px;
      font-size: 32px;
    }
    p.sub-heading {
      line-height: 24px;
      color: $black50;
    }
    .top-section {
      width: 100%;
      display: flex;
      justify-content: center;
      overflow-y: scroll;
      padding: 24px;
    }
    footer {
      display: flex;
      padding: 16px 24px;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid $slate20;
      height: 80px;
      a {
        text-decoration: none;
        display: flex;
        align-items: center;
        svg {
          margin-right: 8px;
        }
      }
      button {
        border: none;
        outline: none;
        background-color: $orange;
        padding: 10px 24px;
        border-radius: 20px;
        font-size: 14px;
        color: $white80;
        height: 46px;
        display: flex;
        align-items: center;
        svg {
          margin-right: 4px;
          path {
            fill: $white80;
            &.wand {
              stroke: $white80
            }
          }
        }
        &:disabled {
          background-color: $slate06;
          color: $slate40;
          svg {
            path {
              fill: #AAABBA;
              &.wand {
                stroke: #AAABBA
              }
            }
          }
        }
      }
    }
  }
  &__form-container {
    width: 100%;
    max-width: 498px;
    .marketing-material {
      margin-bottom: 4px;
      button {
        border: 1px dashed $slate20;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        width: 100%;
        background-color: transparent;
        color: $slate60;
        font-size: 16px;
        svg {
          margin-right: 8px;
        }
      }
      p {
        margin: 8px 0 0;
        &.helper-text {
          font-size: 12px;
          color: $slate40;
        }
      }
    }
  }
}

</style>
