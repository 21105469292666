<template>
  <div class="co-pilot__dropdown mb-3">
    <p>{{ label }}</p>
    <vue-multiselect
      :value="value"
      :options="dropdownItems"
      :show-labels="false"
      @input="changeSelection"
    ></vue-multiselect>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect"

export default {
  name: "CoPilotDropdown",
  components: {
    VueMultiselect
  },
  props: {
    value: {
      type: String,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false
    },
    dropdownItems: {
      type: Array,
      required: true
    }
  },
  methods: {
    changeSelection (item) {
      this.$emit("input", item)
    }
  }
}
</script>

<style lang="scss" scoped>
.co-pilot {
  &__dropdown {
    margin-bottom: 24px;
    p {
      margin-bottom: 8px;
      font-size: 16px;
      color: $black80;
    }
    :deep(.multiselect) {
      min-height: unset;
      .multiselect__tags {
        padding: 16px 40px 16px 8px;
        min-height: unset !important;
      }
      .multiselect__single,
      .multiselect__input,
      .multiselect__placeholder {
        padding: 0 0 0 8px !important;
        margin: 0 !important;
      }
      .multiselect__select {
        top: 15px !important;
      }
      .multiselect__option {
        padding: 16px;
      }
    }
  }
}
</style>
