import { render, staticRenderFns } from "./CoPilotDropdown.vue?vue&type=template&id=275cfd74&scoped=true&"
import script from "./CoPilotDropdown.vue?vue&type=script&lang=js&"
export * from "./CoPilotDropdown.vue?vue&type=script&lang=js&"
import style0 from "./CoPilotDropdown.vue?vue&type=style&index=0&id=275cfd74&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "275cfd74",
  null
  
)

export default component.exports