<template>
  <div class="co-pilot-progress">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.354 2.146a.5.5 0 00-.708 0l-2.438 2.439 3.207 3.207 2.439-2.438a.5.5 0 000-.708l-2.5-2.5zM14.707 8.5L11.5 5.293l-9.354 9.353a.5.5 0 000 .708l2.5 2.5a.5.5 0 00.708 0L14.707 8.5z" fill="#7F8197"/><path class="wand" d="M7 2.5a2 2 0 002 2 2 2 0 00-2 2 2 2 0 00-2-2 2 2 0 002-2zM15.5 11a2 2 0 002 2 2 2 0 00-2 2 2 2 0 00-2-2 2 2 0 002-2z" fill="#7F8197" stroke="#7F8197" stroke-linecap="round" stroke-linejoin="round"/></svg>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.co-pilot-progress {
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: $orange12;
  svg {
    path {
      fill: $orange;
      &.wand {
        stroke: $orange;
      }
    }
  }
}
</style>
